import React, { useEffect, useContext } from 'react'
import queryString from 'query-string'
import {
   Container
  } from "@material-ui/core"
import AuthContext from '../../../../context/auth/authContext'


const ActivateEmail = (props) => {

    const payload = queryString.parse(props.location.search) 
    const { activateUserEmail } = useContext(AuthContext)
    const data = {
        userId: payload.userId,
        token: props.location.search.split("=")[2]
    }
   
    useEffect(() => {
       activateUserEmail(data)
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Container>
            <p>Please wait...</p>
        </Container>
        
    )
}

export default ActivateEmail



























// import React, { useEffect, useContext } from 'react'
// import AuthContext from '../../../../context/auth/authContext'


// const ActivateEmail = (props) => {

//     const { activateUserEmail } = useContext(AuthContext)
//     // const { userId, token } = props.location.search
//     const queryParamsString = window.location.search.substr(1);
//     const queryParams = queryParamsString.split('&').reduce((accumulator, singleQueryParam) => {
//         const [key, value] = singleQueryParam.split('=');
//         accumulator[key] = decodeURIComponent(value);
//         return accumulator;
//       }, {})
//     const data = {
//     //    userId, token
//     }
   
//     useEffect(() => {
        
//        activateUserEmail(queryParams)
       
//     }, [])
//     console.log(props.location)

//     return (
//         <div>
//             <pre>{JSON.stringify(queryParams, null, 2)}</pre>
//         </div>
//     )
// }

// export default ActivateEmail