import React from "react"
import SEO from "../../components/seo"
import ActivateEmail from "../../components/Layout/AuthLayout/ActivateEmail"
import { useLocation } from "@reach/router"

const Activate = () => {

  const location = useLocation()

  return (
    <>
    <SEO title="Home" />
        <ActivateEmail location={location}/>
    </>
  )
}

export default Activate
